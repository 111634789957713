import { useMemo } from "react";
import type { HttpLink } from "@apollo/client";
import { ApolloClient, from, InMemoryCache } from "@apollo/client";
import { introspectionResult } from "@bespeak/apollo";
import type { ErrorLink } from "./useErrorLink";

namespace useClient {
    export interface Props {
        errorLink: ErrorLink;
        httpLink: HttpLink;
    }
}

export function useClient(props: useClient.Props) {
    return useMemo(() => {
        return props?.errorLink && props?.httpLink
            ? new ApolloClient({
                  cache: new InMemoryCache({
                      possibleTypes: introspectionResult.possibleTypes,
                  }),
                  connectToDevTools: import.meta.env.APOLLO_DEVTOOLS === "true",
                  link: from([/*props?.errorLink,*/ props?.httpLink]),
              })
            : null;
    }, [props?.errorLink, props?.httpLink]);
}

export { type ApolloClient };

export default useClient;
